var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-time"},[_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"centered":"","ok-text":_vm.$t('accept'),"cancel-text":_vm.$t('reject')},on:{"ok":_vm.handleTimezoneOkClick,"cancel":_vm.handleCancel},model:{value:(_vm.timezoneModalOpen),callback:function ($$v) {_vm.timezoneModalOpen=$$v},expression:"timezoneModalOpen"}},[_c('select-input',{staticClass:"w-full",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': this,
          'key': 'currentTimezone',
          'prefix': 'post_',
          'options': _vm.timezoneList,
          'clearable': false,
          'disabled': _vm.disabled
        }
      }}})],1),_c('div',{staticClass:"date-pickers__wrapper flex flex-col xl:flex-row xl:gap-5"},[_c('date-picker-input',{staticClass:"date-pickers__date w-full mt-0",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'validation': 'required',
          'model': this,
          'key': 'postDate',
          'clearable': false,
          'disabled': _vm.disabled,
          'minDate': 'today',
        }
      }}}),_c('time-picker-input',{staticClass:"date-pickers__time w-full mt-0",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'validation': 'required',
          'model': this,
          'key': 'postTime',
          'time24hr': true,
          'clearable': false,
          'disabled': _vm.disabled
        },
      }}})],1),_c('div',{staticClass:"flex items-center gap-2"},[_c('a-button',{attrs:{"icon":"global","type":"primary","disabled":_vm.disabled},on:{"click":function($event){_vm.timezoneModalOpen = true}}}),_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$t('post_time_is_shown_as_timezone'))+" "),_c('span',{staticClass:"font-semibold text-primary cursor-pointer hover:opacity-75",on:{"click":function($event){_vm.timezoneModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.scheduleModel.timezone)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }