






























































import { InputSetups } from '@/mixins/input-setups'
import { PostAction, PostActionType } from '@/views/posts-planner/posts.types'
import ActionTypesMixin from '@/views/posts-planner/ActionTypesMixin'
import { actionLabel } from '@/mixins/ActionLabels'

import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FileType } from 'piramis-base-components/src/components/File/types'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      actionLabel,
      FileType
    }
  }
})
export default class ActionItem extends Mixins(ActionTypesMixin, UseFields, InputSetups) {

  @VModel() action!: PostAction & { label: string }

  @Prop({ required: true, type: String }) board !: string

  @Prop({ type: Number }) itemIndex !: number

  @Prop({ type: Number }) lastIndex !: number

  PostActionType: any = PostActionType

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): void => {
      args.model[args.key] = value
    }
    return args
  }

  get isMoveDownButtonDisabled(): boolean {
    return this.itemIndex === this.lastIndex ? true : false
  }

  get isMoveUpButtonDisabled(): boolean {
    return this.itemIndex === 0 ? true : false
  }

}
