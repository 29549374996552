import Vue from "vue";
import Component from "vue-class-component";
import { PostActionType } from "@/views/posts-planner/posts.types";

@Component
export default class ActionTypesMixin extends Vue {
  getActionTypeKey(actionType: PostActionType): string {
    switch (actionType) {
      case PostActionType.DeletePostAction:
        return this.$t('post_action_delete_post') as string
      case PostActionType.SendCommentAction:
        return this.$t('post_action_send_comment') as string
      case PostActionType.EditPostAction:
        return this.$t('post_action_edit_post') as string
      default:
        return ''
    }
  }
}
