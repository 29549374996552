import { PostActionType } from '@/views/posts-planner/posts.types'
import i18n from '@/i18n/i18n'

export function actionLabel(actionType:PostActionType): string {
  switch (actionType) {
    case PostActionType.SendCommentAction:
      return i18n.t('post_action_send_comment').toString()
    case PostActionType.EditPostAction:
      return i18n.t('post_action_edit_post').toString()
    case PostActionType.DeletePostAction:
      return i18n.t('post_action_delete_post').toString()
    case PostActionType.UnpinChatMessageAction:
      return i18n.t('post_action_unpin_message').toString()
    default:
      return ''
  }
}
