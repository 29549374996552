var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reaction-item"},[_c('a-modal',{attrs:{"title":_vm.$t('choose_post_reaction_popup_title'),"footer":null},model:{value:(_vm.areReactionsOpen),callback:function ($$v) {_vm.areReactionsOpen=$$v},expression:"areReactionsOpen"}},[(_vm.reactionList.length)?_c('div',_vm._l((_vm.reactionList),function(r,index){return _c('div',{key:index,staticClass:"mb-2"},[_c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"font-size":"27px"}},[_vm._v(" "+_vm._s(r.text)+" ")]),_c('a-button',{staticClass:"ml-auto btn-success",attrs:{"icon":"check"},on:{"click":function (){_vm.reaction.reaction = r.guid; _vm.selectedReaction=r.text; _vm.areReactionsOpen = false}}})],1)])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t('empty_post_reactions_message'))+" ")])]),_c('a-card',{staticClass:"mb-5",attrs:{"title":_vm.actionLabel(_vm.reaction.action.type)}},[_c('div',{staticClass:"flex flex-row items-center mb-5 justify-end"},[_c('a-button',{attrs:{"type":"danger","icon":"delete"},on:{"click":function($event){return _vm.$emit('remove')}}})],1),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.selectedReaction)+" ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.areReactionsOpen=true}}},[_vm._v(" "+_vm._s(_vm.$t('choose_post_reaction_from_message'))+" ")])],1),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.reaction,
          'key': 'lower_bound',
          'prefix': 'post_reaction_',
          'validation': 'required'
        }
      }}}),(_vm.reaction.action.type !== _vm.PostActionType.DeletePostAction && _vm.reaction.action.type !== _vm.PostActionType.UnpinChatMessageAction)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args':{
          'model': _vm.reaction.action.message,
          'key':'variants',
          'prefix': 'post_',
          'validation': 'required',
          'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
          'has-media': false,
          'settingsStructure': {
            'disable_link_preview': false,
            'disable_notify': false,
            'pin': false,
            'remove_previous': false,
          },
          'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
        }
      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }